<template>
  <NotFound v-if="isNotFoundEverId" type="accountId" :value="accountId" />
  <div v-else class="bg-scanMain pt-20 lg:pt-28 pb-12">
    <Container>
      <div class=" flex " :class="isMailAccount ? 'flex-col' : 'lg:flex-row flex-wrap'">
        <div class="flex mr-20 mt-6 px-4 items-start">
          <div class="mr-2 font-medium whitespace-nowrap">
            {{ t('account_label') }}
          </div>
          <div
            class="break-all cursor-pointer clipboard-modal-accountId flex items-center"
            :data-clipboard-text="account"
          >
            <span class="sm:block hidden">{{ account }}</span>
            <span class="sm:hidden block">{{ formatAccount }}</span>
            <img src="@/images/copy.svg" class="cursor-pointer ml-1 align-top inline-block">
          </div>
        </div>
        <div v-if="isMailAccount" class="px-4 mt-5  flex items-center">
          {{ `${accountId.slice(0, 2)}***${accountId.slice(-3)}` }}
        </div>
        <div class="flex  px-4 flex-row items-center mt-6" style="width: 450px;">
          <div class="mr-2 font-medium whitespace-nowrap">
            {{ t('tokens_label') }}
          </div>
          <div class="relative w-full z-5 totalPriceOptions cursor-pointer" @click="totalPriceList && totalPriceList.length ? displayBalances() : ''">
            <div class="h-9 border border-black-500 border-solid focus:outline-none rounded-lg w-full cursor-pointer px-3" />
            <img src="@/images/lang-arrow-down.svg" class="w-2 h-2 absolute right-2 top-3.5 transition duration-150 ease-linear" :class="isShow ? ' transform rotate-180' : '' ">
            <div v-if="displayTotalAmount" class="flex text-sm items-center absolute top-1 left-2 rounded-md">
              <div class="p-1 opacity-25">
                ${{ formatMoney((+totalPrice.toFixed(2))) }}
              </div>
              <div class="border border-black-500 border-solid h-3.5" />
              <div class="p-1 opacity-25">
                {{ totalPriceList.length }}
              </div>
            </div>
            <div v-else class="flex text-sm items-center absolute left-2" style="top:3px">
              <div class="p-1 flex items-center text-scanMainColor bg-scantokens rounded-md">
                <div class="flex items-center mr-1">
                  <TokenLogo :symbol="tokenSymbolInfo.symbol.toLowerCase()" class="w-3.5 h-3.5" />{{ tokenSymbolInfo.symbol }}
                </div>
                <div class="ml-1 text-12px mr-1 text-black text-opacity-45">
                  {{ formatChainTypeDisplay(tokenSymbolInfo.chainType) }}
                </div>
                ${{ formatMoney((+tokenSymbolInfo.totalAmount * +tokenSymbolInfo.unitPrice).toFixed(2)) }}
                <i class="el-icon-close mt-0.5 text-sm mx-1 leading-none" style="font-weight: 800" @click.stop="displayTotalAmount = true ; currentTokenTag = ''; isShow = false" />
              </div>
            </div>
            <div v-show="isShow">
              <div
                style="max-height:325px"
                class="absolute top-10 w-full z-10 overflow-hidden overflow-y-auto bg-white shadow-accountShadow rounded-b-lg scrollbarDate">
                <div
                  v-for="(item,index) in totalPriceList"
                  :key="index"
                  class="px-4 transition"
                  :style="`${index === hoverIndex ? 'background: rgba(237, 240, 252, 1); ' : ''}`">
                  <div
                    class="py-4 text-sm border-b border-dashed"
                    @click="switchTokenTagTx(item)"
                    @mouseover="hoverIndex = index"
                    @mouseleave="hoverIndex = -1">
                    <div class="flex items-center justify-between">
                      <span class="flex items-center">
                        <TokenLogo :symbol="item.symbol" :chain-type="item.chainType" class="w-3.5 h-3.5 mr-1" />
                        {{ item.symbol ? item.symbol.toUpperCase() : '' }}
                        <div class="ml-1 text-12px text-black text-opacity-45">
                          {{ formatChainTypeDisplay(item.chainType) }}
                        </div>
                      </span>
                      <span>${{ formatMoney((+item.totalAmount* +item.unitPrice).toFixed(2)) }}</span>
                    </div>
                    <div class="flex items-center mt-2 opacity-60 justify-between">
                      <span>{{ t('balance_amount') }} {{ item.totalAmount.length < 4 ? (+item.totalAmount).toFixed(2) : formatMoney(item.totalAmount) }}</span>
                      <!-- <span>@{{ (+item.unitPrice).toFixed(2) }}</span> -->
                      <span>{{ !+item.unitPrice ? '-.-' : `${t('price')} ${(+item.unitPrice).toFixed(2)}` }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="ansAddress" class="px-4 mt-5  flex items-center">
        <img src="@/images/ans.png" class="w-4 h-4 mt-0.5 mr-1 " alt="">
        {{ ansAddress }}
        <div
          class="break-all cursor-pointer clipboard-modal-ansAddress flex items-center mt-0.5"
          :data-clipboard-text="ansAddress"
        >
          <img src="@/images/copy.svg" class="cursor-pointer ml-1 align-top inline-block">
        </div>
      </div>
      <Tab :current-tx-options="currentTxOptions" @switch="switchTxOptions" />
      <Loading v-if="loading" />
      <TableCard
        v-else
        :current-tx-options="currentTxOptions"
        :tbody-list="accountTxsList.txs" />
      <Pagination
        :loading="loading"
        :current-page="currentPage"
        :has-next-page="isNextPage"
        @jump="handlePageChange" />
    </Container>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onActivated, ref, Ref, watch, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import Container from '@/components/Container.vue'
import { getTxsByAccount, getEverpayBalances, getBundleTxsByAccount, getAnsResolver } from '@/libs/api'
import TableCard from '@/components/TableCard.vue'
import Pagination from '@/components/common/Pagination.vue'
import { fromDecimalToUnit, getAccountChainType } from '@/libs/everpay-js/utils/util'
import { useStore } from '@/store'
import Loading from '@/components/common/Loading.vue'
import TokenLogo from '@/components/TokenLogo.vue'
import { useI18n } from 'vue-i18n'
import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'
import { formatMoney, checkParentsHas, handleErrorMsg, isSmartAccount, genEverId, formatChainTypeDisplay } from '@/libs/utils'
import Tab from '@/components/Tab.vue'
import { AllBundleTxs, AllTxs } from '@/pages/interface'
import NotFound from '@/components/common/NotFound.vue'
export default defineComponent({
  components: {
    TableCard,
    Container,
    Pagination,
    Loading,
    TokenLogo,
    Tab,
    NotFound
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const account = ref('')
    const accountId = computed(() => {
      return route.params.accountId as string
    })
    const isMailAccount = computed(() => {
      return isSmartAccount(accountId.value)
    })
    const isEid = computed(() => {
      if (accountId.value) {
        return accountId.value.search(/^eid[0-9a-fA-F]{68}$/) !== -1
      }
      return false
    })
    const isNotFoundEverId = computed(() => {
      let isNormalAccount = true
      try {
        getAccountChainType(accountId.value)
      } catch {
        isNormalAccount = false
      }
      return isMailAccount.value || !(isEid.value || isNormalAccount)
    })
    const formatAccount = computed(() => {
      if (accountId.value) {
        return (account.value).slice(0, 6) + '...' + (account.value).slice(-6)
      }
      return ''
    })
    const tokens = computed(() => store.state.tokenList)
    const currencyPrices = computed(() => store.state.currencyPrices)
    const displayAmountSelect = checkParentsHas('totalPriceOptions')
    const loading = ref(false)
    const hoverIndex = ref(-1)
    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const isShow = ref(false)
    const accountTxsList = ref({} as AllTxs | AllBundleTxs)
    const totalPriceList:Ref<any[]> = ref([])
    const ansAddress = ref('')
    const { t } = useI18n()
    const currencyPricesStack: Ref<any> = computed(() => {
      const result = {}
      store.state.currencyPrices.forEach(currencyPriceItem => {
        (result as any)[currencyPriceItem.symbol.toUpperCase()] = currencyPriceItem.price
      })
      return result
    })
    const genEverAccount = async (accountId:string) => {
      account.value = isMailAccount.value ? await genEverId(accountId) : accountId
      return account.value
    }
    const getAccountAnsAddress = async () => {
      try {
        const result = await getAnsResolver(accountId.value)
        ansAddress.value = result?.domain ? result.domain : ''
      } catch {
        ansAddress.value = ''
      }
    }
    const totalPrice = computed(() => {
      if (totalPriceList.value.length) {
        return totalPriceList.value.reduce((accumulator: number, currentValue: any) => {
          return accumulator + (+currentValue.totalAmount * +currentValue.unitPrice)
        }, 0)
      }
      return 0
    })
    const getTokenArea = async () => {
      totalPriceList.value = []
      if (accountId.value) {
        const acc = await genEverAccount(accountId.value)
        try {
          const accountEverBalances = await getEverpayBalances({ account: acc })
          const totalPrice = accountEverBalances.balances.map((item) => {
            const token = tokens.value.find((content) => {
              return content.tag === item.tag
            })
            const unitPrice = token ? currencyPricesStack.value[token?.symbol.toUpperCase()] : '0'
            return {
              tag: item.tag,
              chainType: token?.chainType.startsWith('arweave') ? 'arweave' : token?.chainType,
              unitPrice,
              decimals: item.decimals,
              symbol: token?.symbol,
              totalAmount: fromDecimalToUnit(item.amount, item.decimals)
            }
          })
          totalPriceList.value = totalPrice.filter((item) => {
            return +item.totalAmount !== 0
          }).sort((a: any, b: any) => {
            if ((+a.totalAmount * +a.unitPrice) || (+b.totalAmount * +b.unitPrice)) {
              return (+b.totalAmount * +b.unitPrice) - (+a.totalAmount * +a.unitPrice)
            } else {
              return +b.totalAmount - +a.totalAmount
            }
          })
        } catch (e:any) {
          handleErrorMsg(e, t)
        }
      }
    }
    const displayTotalAmount = ref(true)
    const tokenSymbolInfo = ref({} as any)
    const currentTokenTag = ref('')
    const switchTokenTagTx = (symbolInfo: any) => {
      tokenSymbolInfo.value = symbolInfo
      currentTokenTag.value = symbolInfo.tag
      displayTotalAmount.value = false
    }
    watch(() => currentTokenTag.value, () => {
      initConst()
      getAccountTxsList()
    })
    const currentTxOptions = ref('Transaction')
    const switchTxOptions = (option: string) => {
      currentTxOptions.value = option
      initConst()
      getAccountTxsList()
    }
    // 初始化变量
    const initConst = () => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
    }
    const setAccountTxsList = async () => {
      const acc = await genEverAccount(accountId.value)
      if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
        pageId.value = pageCachelist.value[pageCacheIndex.value]
      }
      // transaction
      if (currentTxOptions.value === 'Transaction') {
        accountTxsList.value = await getTxsByAccount(acc, pageId.value, currentTokenTag.value)
        isNextPage.value = accountTxsList.value.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (accountTxsList.value.hasNextPage) {
          pageId.value = accountTxsList.value.txs[accountTxsList.value.txs.length - 1].rawId
        }
      } else {
        // bundle
        accountTxsList.value = await getBundleTxsByAccount(acc, pageId.value, currentTokenTag.value)
        isNextPage.value = accountTxsList.value.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (accountTxsList.value.hasNextPage) {
          pageId.value = accountTxsList.value.txs[accountTxsList.value.txs.length - 1].id
        }
      }
    }
    const getAccountTxsList = async () => {
      if (accountId.value) {
        loading.value = true
        try {
          await setAccountTxsList()
          loading.value = false
        } catch (e:any) {
          loading.value = false
          handleErrorMsg(e, t)
          await setAccountTxsList()
        }
      }
    }
    const displayBalances = () => {
      hoverIndex.value = -1
      isShow.value = !isShow.value
    }
    const handlePageChange = (page: number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getAccountTxsList()
    }
    const init = async () => {
      try {
        if (!tokens.value.length) {
          await store.dispatch('updateTokenListAsync')
        }
        if (!currencyPrices.value || !currencyPrices.value.length) {
          await store.dispatch('updateCurrencyPricesAsnyc')
        }
      } catch (e:any) {
        handleErrorMsg(e, t)
      }
      getTokenArea()
    }
    onActivated(async () => {
      initConst()
      init()
      getAccountTxsList()
      getAccountAnsAddress()
      genEverAccount(accountId.value)
    })
    watch(() => accountId.value, async () => {
      displayTotalAmount.value = true
      currentTokenTag.value = ''
      isShow.value = false
      genEverAccount(accountId.value)
      getTokenArea()
      getAccountAnsAddress()
      getAccountTxsList()
      isShow.value = false
    })
    watch(() => tokens.value, () => {
      store.dispatch('updateCurrencyPricesAsnyc')
    })
    let clipboard: any
    let clipboardAnsAddress:any
    onMounted(async () => {
      window.addEventListener('resize', () => {
        isShow.value = false
      })
      document.addEventListener('click', (e) => {
        if (!displayAmountSelect(e.target as any)) {
          isShow.value = false
        }
      })
      clipboardAnsAddress = new ClipboardJS('.clipboard-modal-ansAddress')
      clipboardAnsAddress.on('success', function (e:any) {
        e.clearSelection()
        ElMessage({
          showClose: true,
          message: t('copy_success'),
          type: 'success',
          duration: 2000
        })
      })
      clipboard = new ClipboardJS('.clipboard-modal-accountId')
      clipboard.on('success', function (e: any) {
        e.clearSelection()
        ElMessage({
          showClose: true,
          message: t('copy_success'),
          type: 'success',
          duration: 2000
        })
      })
    })
    onUnmounted(() => {
      clipboard && clipboard.destroy()
    })
    return {
      t,
      accountId,
      hoverIndex,
      accountTxsList,
      totalPriceList,
      displayBalances,
      handlePageChange,
      isShow,
      loading,
      formatMoney,
      switchTxOptions,
      currentTxOptions,
      currentPage,
      switchTokenTagTx,
      tokenSymbolInfo,
      displayTotalAmount,
      currentTokenTag,
      formatAccount,
      ansAddress,
      account,
      isMailAccount,
      isNotFoundEverId,
      totalPrice,
      isNextPage,
      formatChainTypeDisplay
    }
  }
})
</script>
<style></style>
